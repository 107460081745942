var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"edit-summary-messages-modal"}},[_c('div',{attrs:{"data-element":"form"}},[(_vm.showField)?_c('select-field',{attrs:{"name":"Select result","searchable":"","htmlOptions":"","disabled":!!_vm.activeItem,"options":_vm.getDifferenceOptions},on:{"input":_vm.selectDifference}}):_vm._e()],1),_c('div',{attrs:{"data-element":"claim-parameters-list"}},[_c('dynamic-table',{attrs:{"columns":_vm.columns,"data":_vm.getSummaryMessages,"activeRow":_vm.getActiveIndex,"overflow":"visible"},scopedSlots:_vm._u([{key:"difference",fn:function(ref){
var row = ref.row;
var cell = ref.cell;
return [_c('div',{attrs:{"data-element":"difference-cell"}},[_c('span',{attrs:{"data-element":"contract-value"},domProps:{"innerHTML":_vm._s(_vm.getContractValue(_vm.getContract))}}),_c('suit-symbol',{attrs:{"suitName":_vm.getContractSuit(_vm.getContract),"withoutPadding":true}}),_vm._v(" "+_vm._s(cell)+" ")],1)]}},{key:"message",fn:function(ref){
var cell = ref.cell;
return [_c('div',{attrs:{"data-element":"message-wrapper"},domProps:{"innerHTML":_vm._s(cell)}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(!_vm.activeItem)?_c('div',{attrs:{"data-element":"actions-cell"}},[_c('btn',{attrs:{"text":"Edit","size":"sm"},nativeOn:{"click":function($event){return _vm.handleEdit(row)}}}),_c('btn',{attrs:{"text":"Delete","colour":"red","size":"sm","btnId":_vm.getDeleteOptionBtnId(row),"isDisabled":_vm.getDeleteOptionBtnDisabled(row)},nativeOn:{"click":function($event){return _vm.handleDelete(row)}}})],1):_vm._e()]}}])})],1),(_vm.getShowField)?_c('validation-observer',{ref:"form",attrs:{"data-element":"form","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[(_vm.getReusableItemsVisibility)?_c('select-field',{attrs:{"name":"Saved messages","searchable":"","htmlOptions":"","searchIsLoading":_vm.reusableItemsLoading,"options":_vm.getReusableMessagesOptions},on:{"input":_vm.selectReusableMessage,"searchChange":_vm.handleSearchChange}}):_vm._e(),_c('wysiwyg-field',{attrs:{"name":"Message","type":"text","hasEditOptions":false,"hasListControl":false,"hasLinkControl":false,"value":_vm.getActiveItemMessage},on:{"input":_vm.handleOptionInput}}),_c('actions',{attrs:{"actions":_vm.getActions,"isWaiting":_vm.isWaiting},on:{"actionClick":_vm.onActionClick}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }